
export const podiumUIdependencies = [
    "core-web", 
    "styles-web", 
    "themes-web", 
    "icons-web", 
    "visualisers-web"
];

export const podiumUIDataDependencies = [
    "table", 
    "chart", 
    "formobject", 
    "caserequest", 
    "customise",
    "calendar",
    "ganttcomponent",
    "documents",
    "customsave",
    "metadatawrapper",
    "genericcomponent",
    "legacy",
    "filters",
    "querybuilder",
    "tabswrapper",
    "actionbar",
    "underconstruction",
    "orderhistory",
    "drawerpanel",
    "printable",
];